<template>
  <PageContent class="expedition">
    <template #header-right>
      <ButtonGroup>
        <ButtonClassic
          variant="solid"
          icon="right"
          @click="copyToClipboard(currentUrl)"
        >
          <template #right-icon>
            <UilShareAlt />
          </template>
        </ButtonClassic>
      </ButtonGroup>
    </template>

    <template
      v-if="!isLoading"
      #action-bar
    >
      <FiltersActionBar
        v-if="crossFilters?.length"
        :active-filters="crossFilters"
        :total-results="totalRows"
        @delete-tag="uncheckFilter($event)"
        @reset-filters="resetFilters()"
      />
    </template>
    <template
      v-if="!isLoading"
      #content
    >
      <template v-if="!emptyData">
        <ExpeditionsTable
          :rows="expeditions"
          :active-sort="sort"
          @sort="handleSort($event)"
        />
        <div
          v-if="totalRows > pageSize"
          class="pagination-container"
        >
          <Pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="pageSize"
            @change="(page) => $router.push({ query: { ...$route.query, page, }, })"
          />
        </div>
      </template>
      <div
        v-else
        class="empty-page"
      >
        <h2 class="s4 light">
          {{ $t('facture.vous-n-avez-aucune-expedition') }}
        </h2>
        <img
          :src="require('@lde/core_lde_vue/dist/assets/media/illus/illus_cart.svg')"
          :alt="$t('general.alt-image-illustration')"
        />
      </div>
    </template>
    <template
      v-else
      #content
    >
      <div>
        <InfiniteLoader />
      </div>
    </template>
  </PageContent>
</template>

<script>
import {
  ButtonClassic,
  ButtonGroup,
  PageContent,
  InfiniteLoader,
  Pagination,
  copyToClipboard,
} from "@lde/core_lde_vue";

import FiltersActionBar from "@/components/search/filters/FiltersActionBar.vue";
import ExpeditionsTable from "@/components/table/ExpeditionsTable.vue";

import Api from "@/modules/axios";
import { handleSort } from "@/modules/utils";

import { UilShareAlt } from "@iconscout/vue-unicons";

/**
 * Vue de la liste des expéditions.
 */
export default {
  name: "Expedition",
  components: {
    ButtonClassic,
    ButtonGroup,
    InfiniteLoader,
    Pagination,
    PageContent,
    ExpeditionsTable,
    UilShareAlt,
    FiltersActionBar,
  },
  data() {
    return {
      isLoading: true,
      expeditions: [],
      currentPage: 1,
      pageSize: 24,
      totalRows: 0,
      sort: {},
      currentUrl: window.location.href,
    };
  },
  computed: {
    emptyData() {
      return !this.isLoading && !this.expeditions?.length;
    },
    crossFilters() {
      // Filtres venant d'autres vues. Utilisés pour trouver les factures, commandes et expéditions liées.
      const res = [];

      const fact = this.$route.query.facture;
      if (fact) {
        res.push({
          libelle: `Facture #${fact}`,
          id: fact,
          key: "facture",
        });
      }

      const cmd = this.$route.query.commande;
      if (cmd) {
        res.push({
          libelle: `Commande #${cmd}`,
          id: cmd,
          key: "commande",
        });
      }

      return res;
    },
  },
  watch: {
    /**
     * Quand la recherche change dans la queryString
     */
    "$route.query": {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          const toNumber = (value) => parseInt(value, 10);

          if (toNumber(newValue.page) !== toNumber(oldValue.page)) {
            this.currentPage = toNumber(newValue.page) || 1;
          }

          if (newValue.sort !== oldValue.sort) {
            this.sort.sortAscended = newValue.sort.charAt(0) !== "-";
            this.sort.key = newValue.sort.replace("-", "");
          }

          this.fetchExpeditions();
        }
      },
    },
  },
  mounted() {
    const { page, sort } = this.$route.query;
    if (page) {
      this.currentPage = parseInt(page, 10);
    }
    if (sort) {
      this.sort.sortAscended = sort.charAt(0) !== "-";
      this.sort.key = sort.replace("-", "");
    }
    this.fetchExpeditions();
  },
  methods: {
    copyToClipboard,
    handleSort,
    /**
     * Récupère les expéditions.
     */
    fetchExpeditions() {
      this.isLoading = true;

      const params = {
        page_size: this.pageSize,
        page: this.currentPage,
      };

      if (this.sort?.key) {
        params.ordering = `${this.sort.sortAscended ? "" : "-"}${this.sort.key}`;
      }

      this.crossFilters.forEach((f) => {
        params[`${f.key}__in`] = f.id;
      });

      Api().get("/expedition/", { params })
        .then(({ data: { results, count } }) => {
          this.expeditions = results;
          this.totalRows = count;
        })
        .catch((err) => {
          this.$toast.error({ title: err.data || err });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    /**
     * Réinitialise les filtres et met la paginsation sur 1.
     */
    resetFilters() {
      this.$router.push({ name: this.$route.name });
      this.currentPage = 1;
    },
    /**
     * Enlève le filtre et rafraîchit les résultats.
     * @param {Object} filter Filtre à supprimer.
     */
    uncheckFilter(filter) {
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, [filter.key]: undefined },
      });
    },
  },
};
</script>

<style lang="scss"></style>
